<template>
  <div>
    <div class="box">
      <div class="main">
<!--        <div class="search">-->
<!--          <span>成员名称：</span>-->
<!--          <el-input v-model="input" placeholder=""></el-input>-->
<!--          <span>关键字：</span>-->
<!--          <input type="text" v-model="getPurchaseForm.Key"  placeholder="请输入关键字" />-->
<!--          <button>查询</button>-->
<!--          <button>重置</button>-->
<!--        </div>-->
        <div class="operation">
          <el-button @click="addProcureDialog = true" type="primary">+ 新建</el-button>
<!--          <button>批量删除</button>-->
        </div>
        <!-- 表格内容 -->
        <div class="primary">
          <el-table
            max-height="660px"
            ref="multipleTable"
            :data="PurchaseList"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
            row-key="id"
            border
            lazy
          >
            <el-table-column type="selection" width="40" align="center">
            </el-table-column>
             <el-table-column
              type="index"
              label="序号"
              min-width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="HospitalName"
              label="组织名称"
              min-width="80"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="UserName"
              label="联系人"
              min-width="40"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="PhoneNumber"
              label="联系电话"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="CreateDate"
              label="创建日期"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column label="配置" min-width="80" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="margin-right: 10px;font-size: 16px; color: #3978E7;font-weight: 400;"
                  @click="showEditProcure(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  style="font-size: 16px;color: #3978E7;font-weight: 400;"
                  type="text"
                  @click="showDelProcure(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="getPurchaseForm.PageIndex"
              :page-sizes="[10,20,30]"
              :page-size="getPurchaseForm.PageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="memberTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 弹出框 -->
      <div class="add-procure">
        <el-dialog
          :visible.sync="addProcureDialog"
          width="520px"
          top="20vh"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">新增组织</span>
          </div>
          <ul>
            <li>
              <span class="span-name">组织单位名称：</span>
              <el-autocomplete
                style="width: 350px; text-align: center"
                v-model="HospitalValue"
                :fetch-suggestions="addProjectQuerySearchAsync"
                placeholder="请输入关键字"
                :trigger-on-focus="false"
                @select="addProcureHeaderSelect"
                clearable
              ></el-autocomplete>
            </li>
            <li>
              <span class="span-name">联系人：</span>
              <el-select
                value-key="Id"
                v-model="memberValue"
                placeholder="请选择"
                @change="checkMemberSelect"
              >
                <el-option
                  v-for="item in memberOptions"
                  :key="item.Id"
                  :label="item.UserName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <span class="span-name">项目创建次数：</span>
              <el-input v-model="purchaseForm.UseNumber"  placeholder="请输入次数" class="input-name"></el-input>
              <span class="span-name" style="color:#606266">(-1为无限次数)</span>
            </li>
            <li>
              <span class="span-name">系统选择：</span>
              <div>
                <el-checkbox v-model="checkedWork" @change="checkWorkValue"
                  >科研事务</el-checkbox
                >
              </div>
            </li>
          </ul>
          <div class="footer-btn">
            <el-button @click="addProcureDialog = false">取消</el-button>
            <el-button @click="submintProcure">保存</el-button>
          </div>
        </el-dialog>
      </div>
      <div class="add-procure">
        <el-dialog
          :visible.sync="editProcureDialog"
          width="550px"
          top="20vh"
          v-dialogDrag
        >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">编辑组织信息</span>
          </div>
          <ul>
            <li>
              <span class="span-name">组织单位名称：</span>
              <el-input
                style="width: 350px; text-align: center"
                v-model="editHospitalValue"
                :disabled="true"
              ></el-input>
            </li>
            <li>
              <span class="span-name">联系人：</span>
              <el-select
                value-key="Id"
                v-model="editmemberValue"
                placeholder="请选择"
                @change="checkEditMemberSelect"
              >
                <el-option
                  v-for="item in editMemberOptions"
                  :key="item.Id"
                  :label="item.UserName"
                  :value="item"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <span class="span-name">项目创建次数：</span>
              <el-input v-model="editUseNumber"  placeholder="请输入次数" class="input-name"></el-input>
              <span class="span-name" style="color:#606266">(-1为无限次数)</span>
            </li>
            <li>
              <span class="span-name">系统选择：</span>
              <div>
                <el-checkbox
                  v-model="editCheckedWork"
                  @change="editCheckWorkValue"
                  >科研事务</el-checkbox
                >
              </div>
            </li>
          </ul>
          <div class="footer-btn">
            <el-button @click="editProcureDialog = false">取消</el-button>
            <el-button @click="submintEditProcure">确定</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { getDepartmentInfo, getHospitalList } from "@/api/project.js";
import {
  getPurchasePagedList,
  deletePurchase,
  updatePurchase,
  insertPurchase,
  getHospitalAdminList,
  getPurchaseInfo,
} from "@/api/project.js";
export default {
  data() {
    return {
      UseNumber: "",
      // editPurchaseForm: {
      //   HospitalId: "",
      // },
      editUseNumber:"",
      editCheckedWork: false,
      editCheckedData: false,
      editmemberValue: "",
      editHospitalValue: "",
      purchaseForm: {
        HospitalId: "",
        HospitalName: "",
        PurchaseAdmin: "",
        purchaseSys: "",
        UseNumber: ""
      },
      editPurchaseForm: {
        Id: "",
        PurchaseAdmin: "",
        purchaseSys: "",
        UseNumber: ""
      },
      HospitalValue: "",
      editMemberOptions: [],
      memberOptions: [],
      memberValue: "",
      checkedWork: true,
      checkedData: false,
      getPurchaseForm: {
        Key: "",
        PageIndex: 0,
        PageSize: 10,
      },
      input: "",
      DepartmentOptions: [],
      checkList: [],
      userRolesList: [],
      queryInfo: {
        PageIndex: 0,
        PageSize: 1,
      },
      userQueryInfo: {
        Key: "",
        PageIndex: 0,
        PageSize: 8,
      },
      Key: "",
      PageIndex: 0,
      PageSize: 8,
      proTotal: 0,
      memberTotal: 0,
      item: {},
      // 医院编码
      CenterCode: "",

      // 医院搜索
      restaurants: [],
      unitstate: "",
      addstate: "",
      editstate: "",
      timeout: null,
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      addUserForm: [],
      editForm: {},
      PurchaseList: [],
      hospitalData: [],
      addProcureDialog: false,
      editMemberDialog: false,
      editDialogVisible: false,
      editProcureDialog: false,
    };
  },
  components: {
  },
  created() {
    this.getPurchaseList();
  },
  mounted() {
    this.getHospital("");
  },
  methods: {
    async submintEditProcure() {
      if (this.editCheckedWork == true && this.editCheckedData == true) {
        this.editPurchaseForm.purchaseSys = "1,1";
      } else if (
        this.editCheckedWork == true &&
        this.editCheckedData == false
      ) {
        this.editPurchaseForm.purchaseSys = "1,0";
      } else if (
        this.editCheckedWork == false &&
        this.editCheckedData == true
      ) {
        this.editPurchaseForm.purchaseSys = "0,1";
      }
      this.editPurchaseForm.UseNumber = this.editUseNumber
      const res = await updatePurchase(this.editPurchaseForm);
      console.log(res);
      if (res.data.Status == 1) {
        this.editProcureDialog = false;
        this.getPurchaseList();
        return this.$message.success(res.data.Message);
      } else {
        return this.$message.error(res.data.Message);
      }
    },
    checkEditMemberSelect(value) {
      console.log(value);
      this.editPurchaseForm.PurchaseAdmin = value.Id;
    },
    editCheckWorkValue(value) {
      console.log(value);
    },
    editCheckDataValue(value) {
      console.log(value);
    },
    checkWorkValue() {
      console.log(this.checkedWork);
    },
    checkDataValue() {
      console.log(this.checkedData);
    },
    async submintProcure() {
      if (this.checkedWork == true && this.checkedData == true) {
        this.purchaseForm.purchaseSys = "1,1";
      } else if (this.checkedWork == true && this.checkedData == false) {
        this.purchaseForm.purchaseSys = "1,0";
      } else if (this.checkedWork == false && this.checkedData == true) {
        this.purchaseForm.purchaseSys = "0,1";
      }
      const res = await insertPurchase(this.purchaseForm);
      console.log(res);
      if (res.data.Status !== 1) {
        return this.$message.error(res.data.Message);
      } else {
        this.addProcureDialog = false;
        this.HospitalValue = "";
        this.memberValue = "";
        this.checkedWork = false;
        this.checkedData = false;
        this.getPurchaseList();
        return this.$message.success(res.data.Message);
      }
    },
    checkMemberSelect(value) {
      console.log(value);
      this.purchaseForm.PurchaseAdmin = value.Id;
    },
    // 展示编辑对话框
    async showEditProcure(item) {
      console.log(item);
      if (item.purchaseSys == "1,1") {
        this.editCheckedWork = true;
        this.editCheckedData = true;
      } else if (item.purchaseSys == "1,0") {
        this.editCheckedWork = true;
        this.editCheckedData = false;
      } else if (item.purchaseSys == "0,1") {
        this.editCheckedWork = false;
        this.editCheckedData = true;
      }
      this.editPurchaseForm.purchaseSys = item.purchaseSys;
      this.editPurchaseForm.Id = item.Id;
      this.editPurchaseForm.PurchaseAdmin = item.PurchaseAdmin;
      // this.editPurchaseForm.UseNumber = item.UseNumber;
      const parame = {};
      parame.Id = item.Id;
      const res = await getPurchaseInfo(parame);
      this.editHospitalValue = res.data.NewID.HospitalName;
      this.editmemberValue = res.data.NewID.UserName;
      this.editUseNumber = res.data.NewID.UseNumber
      const obj = {};
      obj.HospitalId = item.HospitalId;
      const arr = await getHospitalAdminList(obj);
      console.log(arr);
      this.editMemberOptions = arr.data.Entity;
      this.editProcureDialog = true;
    },
    // 编辑成员
    // async showEditProcure(item) {
    //   const parame = { userId: item.Id };
    //   const res = await updatePurchase(parame);
    //   console.log(res);
    //   if (res.data.Status !== 1) {
    //     return this.$message.error("查询失败");
    //   }
    // },

    // 删除成员
    showDelProcure(item) {
      this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          console.log(item);
          const params = {};
          params.Id = item.Id;
          console.log(params);
          const res = await deletePurchase(params);
          console.log(res);
          if (res.data.Status == 1) {
            this.$message.success("删除成功！");
            this.getPurchaseList();
          } else {
            return this.$message.error("删除失败！");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 组织列表
    async getPurchaseList() {
      console.log("+++");
      const res = await getPurchasePagedList(this.getPurchaseForm);
      if (res.data.Status !== 1) return this.$message.error("获取列表失败！");
      this.PurchaseList = res.data.Entity;
      this.memberTotal = res.data.TotalRowsCount
      console.log(this.memberTotal)
      console.log(res);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 项目基本信息页面科室搜索
    async addProcureHeaderSelect(item) {
      this.purchaseForm.HospitalId = item.Id;
      this.purchaseForm.HospitalName = item.HospitalName;
      // this.ProjectForm.HospitalId = item.Id;
      console.log(item);
      const parame = {};
      parame.HospitalId = item.Id;
      let res = await getHospitalAdminList(parame);
      console.log(res);
      this.memberOptions = res.data.Entity;
    },
    async addProjectQuerySearchAsync(queryString, cb) {
      console.log(queryString);
      let arr = await this.getHospital(queryString);
      // 调用 callback 返回建议列表的数据

      cb(this.restaurants);
      console.log(this.restaurants);
    },
    async querySearchAsync(queryString, cb) {
      console.log(queryString);
      let arr = await this.getHospital(queryString);
      console.log(arr);
      cb(this.restaurants);
    },
    // 单位维护页面医院添加
    unithandleSelect(item) {
      console.log(item);
      var isBoole = true;
      this.hospitalData.forEach((element) => {
        if (element.Id == item.Id) {
          isBoole = false;
        }
      });
      if (isBoole) {
        this.hospitalData.push(item);
      }
      console.log(this.hospitalData);
    },

    // 项目成员维护页面新增成员框医院搜索
    async getHospital(keyValue) {
      let params = { key: keyValue };
      let res = await getHospitalList(params);
      if (res.data.Status == 1) {
        res.data.Entity.forEach((element) => {
          element.value = element.HospitalName;
        });
        this.restaurants = res.data.Entity;
      } else {
        this.restaurants = [];
      }
      return this.restaurants;
    },

    async addUserQuerySearchAsync(queryString, cb) {
      console.log(queryString);
      let arr = await this.getHospital(queryString);
      console.log(arr);
      cb(this.restaurants);
      console.log(this.restaurants);
    },

    // 编辑成员页医院选择框
    editMemberhandleSelect(item) {
      console.log(item);
    },
    // 监听pagesize（每页显示多少条数据）改变的事件
    handleSizeChange(newSize) {
      this.getPurchaseForm.PageSize = newSize;
      // this.getUserList();
      this.getPurchaseList()
    },
    // 监听pagenum（页码值）改变的事件
    handleCurrentChange(newPage) {
      this.getPurchaseForm.PageIndex = newPage -1;
      // this.getUserList();
      this.getPurchaseList()
    },

    // 展示编辑成员的对话框
    async showEditMemberDialog(item) {
      console.log(item);
      // this.editForm = item;
      this.editMemberDialog = true;
    },

    formatterSex(row, column) {
      if ((row.Sex == 1)) {
        return "男";
      }
      return "女";
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/mixin.scss";
.box {
  overflow: hidden;
  position: absolute;
  //top: 0;
  width: 100%;
  /deep/.el-breadcrumb__inner a,
  /deep/.el-breadcrumb__inner.is-link {
    font-size: 15px;
    color: #00f6ff;
    cursor: pointer;
  }
  /deep/.el-breadcrumb__item:last-child/deep/.el-breadcrumb__inner:hover {
    color: #00f6ff;
    cursor: pointer;
  }

  .main {
    //border: 1px solid #00f6ff;
    border-top: 0;
    height: 80%;
    margin: 0 20px;
    .operation {
      width: 100%;
      margin: 20px 20px;
      button {
        //padding: 4px 15px;
        //margin-right: 10px;
        //outline: none;
        //border: 0;
        //font-size: 12px;
        //border-radius: 4px;
        //cursor: pointer;
      }
      button:nth-child(1) {
        //background-color: rgb(24, 144, 255);
        //color: #fff;
      }
      button {
        //background-color: #fff;
      }
    }
    .primary {
      margin: 10px 20px 0;
      height: 80%;
      width: 65%;
      overflow: hidden;
      //border-color: #031c65;
      /* //element-ui table的去除右侧滚动条的样式 */
      ::-webkit-scrollbar {
        width: 2px;
        height: 1px;
      }
      /* // 滚动条的滑块 */
      ::-webkit-scrollbar-thumb {
        // background-color: #00f6ff;
        border-radius: 0px;
      }
      /deep/ .related {
        color: #fff;
      }
      /deep/.el-table::before {
        height: 0;
        background-color: #031c65;
      }
      /deep/ .el-table th {
        padding: 8px 0;
      }
      /deep/ .el-table--enable-row-transition .el-table__body td,
      .el-table .cell,
      .el-table__body {
        color: #606266!important;
        .el-button {
          background-color: transparent;
          border: 0;
        }
      }
      .block {
        position: fixed;
        bottom: 15px;
        .left {
          margin-left: 20px;
          height: 32px;
          line-height: 32px;
          float: left;
          span {
            font-weight: normal;
            //  color: #07c5dc;
            color: #fff;
          }
          i {
            font-style: normal;
          }
        }
        .el-pagination {
          float: left;
        }
      }
      .el-table {
        /deep/td {
          padding: 5px 0;
          /deep/.el-table-column--selection .cell {
            padding-left: 0;
          }
        }
        /deep/.cell {
          padding-left: 5px;
          .el-button + .el-button {
            margin-left: 0;
          }
          .el-button--mini {
            padding: 5px 5px;
          }
        }
      }
    }
  }
  .add-procure {
    /deep/.el-dialog {
      border-radius: 10px;
      .el-dialog__header {
        border-radius: 10px 10px 0px 0px;
        background: #3388ff;
        .header-title {
          .title-name {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 4px solid #ffffff;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 4px;
            margin-bottom: 5px;
          }
          .title-age {
            vertical-align: top;
            display: inline-block;
            @include add-size($font_size_16);
            color: #ffffff;
          }
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #fff;
        }
      }
      .el-dialog__body {
        padding: 0 10px 20px 0;
        ul {
          margin-top: 20px;
          li {
            .span-name {
              color: #585858;
              display: inline-block;
              width: 140px;
              @include add-size($font_size_16);
            }
            .input-name {
              width: 200px;
              @include add-size($font_size_16);
            }
            .el-autocomplete {
              .el-input {
                line-height: 40px;
                .el-input__inner {
                  height: 40px;
                  line-height: 40px;
                  @include add-size($font_size_16);
                }
              }
            }
            .el-checkbox__inner {
              width: 14px;
            }
            .el-checkbox__label {
              color: #666666;
              line-height: 40px;
              @include add-size($font_size_16);
            }
            .el-select {
              width: 350px;
              .el-input {
                .el-input__inner {
                  height: 40px;
                  line-height: 40px;
                  @include add-size($font_size_16);
                }
                .el-input__icon {
                  line-height: 40px;
                }
              }
            }
          }
        }
        .footer-btn {
          margin-top: 32px;
          margin-bottom: 20px;
          text-align: center;
          button:nth-child(1) {
            width: 180px;
            background: #ffffff;
            border: 1px solid #3388ff;
            height: 50px;
            border-radius: 10px;
            font-size: 24px;
            color: #3388ff;
            margin-right: 20px;
          }
          button:nth-child(2) {
            width: 180px;
            border: 1px solid #3388ff;
            background: #3388ff;
            height: 50px;
            border-radius: 10px;
            font-size: 24px;
            color: #fff;
          }
        }
      }
      .el-dialog__footer {
        margin-left: 100px;
        //text-align: none;
      }
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(4, 244, 255, 0.8);
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background: rgba(4, 244, 255, 0.3);
      }
      .el-button {
        margin-left: 0;
        line-height: 0.5;
      }
    }

    ul {
      margin: 0 auto;
      li {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        width: 100%;
        span {
          display: inline-block;
          width: 100px;
          font-size: 12px;
          text-align: right;
          color: #fff;
        }
        input {
          width: 350px;
          height: 20px;
          border-radius: 2px;
          border: 0;
          outline: none;
        }
        input::-webkit-input-placeholder {
          text-indent: 0.2em;
          /* placeholder颜色 */
          color: #aab2bd;
          /* placeholder字体大小 */
          font-size: 12px;
        }
        label {
          display: inline-block;
          margin-right: 12px;
          font-size: 12px;
          color: #fff;
          cursor: pointer;
          input {
            height: 1vw;
            width: 1vw;
            transform: translateY(2.5px);
          }
        }
      }
      /deep/.el-upload-dragger {
        width: 350px;
        height: 80px;
        .el-icon-upload {
          font-size: 50px;
          margin: 0;
        }
        .el-upload__text {
          font-size: 12px;
          color: #595959;
        }
      }
      /deep/.el-upload__tip {
        color: #fff;
      }
    }
  }
}
.primary /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.primary /deep/.el-table__row {
  @include add-size($font_size_16);
  vertical-align: middle;
}
</style>
